/**
 * Type: ページ
 * What: イルミネーション
 */
import React, { useEffect, useState } from 'react';
import { graphql, Link } from 'gatsby';
import Layout from '../components/Layout';
import SEO from '../components/Seo';
import Image from '../util/Image';
import BreadcrumbComponent from '../components/Breadcrumb';
import SimpleSwiper from '../components/Slider.fade';
import {
  ILLUMINATIONS_CATEGORIES,
  ILLUMINATIONS_CATEGORIES2,
  EVENT_BOX,
} from '../config/Illumination';
import {
  ContentsListIllumination,
  ContentsListIlluminationBox,
} from '../components/ContentsListIllumination';
import mainImg2 from '../images/illumination/main-illumination.jpg';
import mainImg2SP from '../images/illumination/main-illumination-sp.jpg';

const pageTitle =
  'WINTER LIGHT FANTASY ILLUMINATION supported by 地球人．jp株式会社';
const pageDescription =
  '冬の季節を華やかに彩る光り輝くイルミネーション「WINTER LIGHT FANTASY」。みずほPayPayドームやBOSS E・ZO FUKUOKA、MARK IS 福岡ももち、ヒルトン福岡シーホーク、一帯のエリアでご覧いただけます。';
const pageSlug = 'illumination';
const pageLogo = 'logo-illumination.png';

export const query = graphql`
  {
    site {
      siteMetadata {
        uuidEvent
        ticket
        ticketReady
      }
    }
  }
`;

const sliderDataobj = [
  {
    filename: mainImg2,
    noAnimation: true,
  },
];

// Data Props Template
const Template = ({ data }) => {
  const url = data.site.siteMetadata;
  const SITE_TITLE = data.site.siteMetadata.title;

  const [isModal, setIsModal] = useState(-1);
  const [isModalBuilding, setIsModalBuilding] = useState(-1);
  const [isModalEvent, setIsModalEvent] = useState(-1);

  // モーダル展開
  const onClickModal = (e, type) => {
    const num = Number(e.currentTarget.dataset.modal);
    if (type == 'building') {
      setIsModalBuilding(num);
    } else if (type == 'event') {
      setIsModalEvent(num);
    } else {
      setIsModal(num);
    }
  };

  // モーダル閉じる
  const onCloseModal = (type) => {
    if (type == 'building') {
      setIsModalBuilding(-1);
    } else if (type == 'event') {
      setIsModalEvent(-1);
    } else {
      setIsModal(-1);
    }
  };

  // モーダルコンテンツ
  const ModalContent = () => {
    if (ILLUMINATIONS_CATEGORIES[isModal]) {
      const theme =
        ILLUMINATIONS_CATEGORIES[isModal] &&
        ILLUMINATIONS_CATEGORIES[isModal].theme;
      const name =
        ILLUMINATIONS_CATEGORIES[isModal] &&
        ILLUMINATIONS_CATEGORIES[isModal].name;
      const detail =
        ILLUMINATIONS_CATEGORIES[isModal] &&
        ILLUMINATIONS_CATEGORIES[isModal].detail;
      const img =
        ILLUMINATIONS_CATEGORIES[isModal] &&
        ILLUMINATIONS_CATEGORIES[isModal].imgModal;
      const colorClass =
        ILLUMINATIONS_CATEGORIES[isModal] &&
        ILLUMINATIONS_CATEGORIES[isModal].colorClass;
      const imgSlider =
        ILLUMINATIONS_CATEGORIES[isModal] &&
        ILLUMINATIONS_CATEGORIES[isModal].imgSlider;
      const sliderImg =
        ILLUMINATIONS_CATEGORIES[isModal] &&
        ILLUMINATIONS_CATEGORIES[isModal].sliderImg;
      return (
        <div className={`modal e-modal ${isModal > -1 ? 'is-active' : ''}`}>
          {/* eslint-disable-next-line max-len */}
          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
          <div className="modal-background" onClick={onCloseModal} />
          <button
            type="button"
            className="delete"
            aria-label="close"
            data-sal="zoom-in"
            data-sal-delay="300"
            data-sal-easing="ease-out-expo"
            onClick={onCloseModal}
          />
          <div className={`modal-content c-${colorClass}`}>
            <div className="e-modal-image">
              <figure className="image">
                {imgSlider ? (
                  <SimpleSwiper data={sliderImg} />
                ) : (
                  <Image filename={img || 'now-printing.jpg'} />
                )}
              </figure>
            </div>
            <div className="e-modal-content">
              <p className="theme">{theme}</p>
              <h3 className="ttl c-4">{name}</h3>
              <p dangerouslySetInnerHTML={{ __html: detail }} />
            </div>
          </div>
        </div>
      );
    }
    if (ILLUMINATIONS_CATEGORIES2[isModalBuilding]) {
      const theme =
        ILLUMINATIONS_CATEGORIES2[isModalBuilding] &&
        ILLUMINATIONS_CATEGORIES2[isModalBuilding].theme;
      const name =
        ILLUMINATIONS_CATEGORIES2[isModalBuilding] &&
        ILLUMINATIONS_CATEGORIES2[isModalBuilding].name;
      const detail =
        ILLUMINATIONS_CATEGORIES2[isModalBuilding] &&
        ILLUMINATIONS_CATEGORIES2[isModalBuilding].detail;
      const img =
        ILLUMINATIONS_CATEGORIES2[isModalBuilding] &&
        ILLUMINATIONS_CATEGORIES2[isModalBuilding].img;
      const colorClass =
        ILLUMINATIONS_CATEGORIES2[isModalBuilding] &&
        ILLUMINATIONS_CATEGORIES2[isModalBuilding].colorClass;
      const imgSlider =
        ILLUMINATIONS_CATEGORIES2[isModalBuilding] &&
        ILLUMINATIONS_CATEGORIES2[isModalBuilding].imgSlider;
      const sliderImg =
        ILLUMINATIONS_CATEGORIES2[isModalBuilding] &&
        ILLUMINATIONS_CATEGORIES2[isModalBuilding].sliderImg;
      const type = 'building';
      const link =
        ILLUMINATIONS_CATEGORIES2[isModalBuilding] &&
        ILLUMINATIONS_CATEGORIES2[isModalBuilding].link;
      const linkText =
        ILLUMINATIONS_CATEGORIES2[isModalBuilding] &&
        ILLUMINATIONS_CATEGORIES2[isModalBuilding].linkText;
      const isExternalLink =
        ILLUMINATIONS_CATEGORIES2[isModalBuilding] &&
        ILLUMINATIONS_CATEGORIES2[isModalBuilding].isExternalLink;

      console.log(link);

      return (
        <div
          className={`modal e-modal ${isModalBuilding > -1 ? 'is-active' : ''}`}
        >
          {/* eslint-disable-next-line max-len */}
          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
          <div
            className="modal-background"
            onClick={() => onCloseModal(type)}
          />
          <button
            type="button"
            className="delete"
            aria-label="close"
            data-sal="zoom-in"
            data-sal-delay="300"
            data-sal-easing="ease-out-expo"
            onClick={() => onCloseModal(type)}
          />
          <div className={`modal-content c-${colorClass}`}>
            <div className="e-modal-image">
              <figure className="image">
                {imgSlider ? (
                  <SimpleSwiper data={sliderImg} />
                ) : (
                  <Image filename={img || 'now-printing.jpg'} />
                )}
              </figure>
            </div>
            <div className="e-modal-content">
              <p className="theme">{theme}</p>
              <h3 className="ttl c-4">{name}</h3>
              <div dangerouslySetInnerHTML={{ __html: detail }} />
              {link && (
                <p className="underline-link u-mt-10">
                  {isExternalLink ? (
                    <a href={link} target="_blank" rel="noopener noreferrer">
                      {linkText}
                    </a>
                  ) : (
                    <a href={link} className="no-link">
                      {linkText}
                    </a>
                  )}
                </p>
              )}
            </div>
          </div>
        </div>
      );
    }
    if (EVENT_BOX[isModalEvent]) {
      const theme = EVENT_BOX[isModalEvent] && EVENT_BOX[isModalEvent].theme;
      const time = EVENT_BOX[isModalEvent] && EVENT_BOX[isModalEvent].time;
      const desc = EVENT_BOX[isModalEvent] && EVENT_BOX[isModalEvent].desc;
      const ttl = EVENT_BOX[isModalEvent] && EVENT_BOX[isModalEvent].ttl;
      const subTtl = EVENT_BOX[isModalEvent] && EVENT_BOX[isModalEvent].subTtl;
      const descDl = EVENT_BOX[isModalEvent] && EVENT_BOX[isModalEvent].descDl;
      const img = EVENT_BOX[isModalEvent] && EVENT_BOX[isModalEvent].img;
      const colorClass =
        EVENT_BOX[isModalEvent] && EVENT_BOX[isModalEvent].colorClass;
      const imgSlider =
        EVENT_BOX[isModalEvent] && EVENT_BOX[isModalEvent].imgSlider;
      const sliderImg =
        EVENT_BOX[isModalEvent] && EVENT_BOX[isModalEvent].sliderImg;
      const isExternalLink =
        EVENT_BOX[isModalEvent] && EVENT_BOX[isModalEvent].isExternalLink;
      const link = EVENT_BOX[isModalEvent] && EVENT_BOX[isModalEvent].link;
      const linkText =
        EVENT_BOX[isModalEvent] && EVENT_BOX[isModalEvent].linkText;

      const type = 'event';
      return (
        <div
          className={`modal e-modal ${isModalEvent > -1 ? 'is-active' : ''}`}
        >
          {/* eslint-disable-next-line max-len */}
          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
          <div
            className="modal-background"
            onClick={() => onCloseModal(type)}
          />
          <button
            type="button"
            className="delete"
            aria-label="close"
            data-sal="zoom-in"
            data-sal-delay="300"
            data-sal-easing="ease-out-expo"
            onClick={() => onCloseModal(type)}
          />
          <div className={`modal-content c-${colorClass}`}>
            <div className="e-modal-image">
              <figure className="image">
                {imgSlider ? (
                  <SimpleSwiper data={sliderImg} />
                ) : (
                  <Image filename={img || 'now-printing.jpg'} />
                )}
              </figure>
            </div>
            <div className="e-modal-content">
              {/* <p className="theme">
                {theme}
              </p>
              <h3 className="ttl c-4">{name}</h3>
              <p
                dangerouslySetInnerHTML={{ __html: detail }}
              /> */}
              <div className="mb-3">
                <p className="theme">{theme}</p>
                <h3 className="ttl c-4 mb0">{ttl}</h3>
                {subTtl && <h3 className="ttl c-4 mb-1">{subTtl}</h3>}
                {descDl && <p className="text mt-3">{descDl}</p>}
              </div>
              {time && <p className="text mt-3">運行時間　{time}</p>}
              {desc && (
                <div
                  className="text mt-3"
                  dangerouslySetInnerHTML={{ __html: desc }}
                />
              )}
              {isExternalLink ? (
                <p className="underline-link">
                  <a href={link} target="_blank" rel="noopener noreferrer">
                    {linkText}
                  </a>
                </p>
              ) : (
                <p className="underline-link">
                  <Link to={link} className="no-link">
                    {linkText}
                  </Link>
                </p>
              )}
            </div>
          </div>
        </div>
      );
    }
    return null;
  };
  const [fetchReady, setFetchReady] = useState(false);

  useEffect(() => {
    setFetchReady(true);
  }, []);

  return (
    <Layout pageSlug={pageSlug}>
      <SEO title={pageTitle} description={pageDescription} />
      <BreadcrumbComponent hierarchy={2} bread2title={pageTitle} />
      <section className="pageMain bs-4">
        <div className="kv-wrap">
          <div className="kv-inner">
            <div className="kv-image">
              <picture>
                <source
                  media="(min-width:768px)"
                  srcSet={mainImg2}
                  width="1920"
                  height="1080"
                />
                <source
                  media="(max-width:767px)"
                  srcSet={mainImg2SP}
                  width="1280"
                  height="1105"
                />
                <img src="/assets/img/common/no_image.svg" alt="" />
              </picture>
            </div>
          </div>

          <div className="container">
            <div className="inner tac">
              <div className="overview mb30">
                <p className="text c-12">
                  この冬の季節を華やかに彩る光り輝くイルミネーションを開催。
                  <br />
                  今年はみずほPayPayドームの正面をカラフルにライトアップし、色鮮やかに彩った素敵な空間を演出します。
                  <br />
                  BOSS Ｅ・ZO
                  FUKUOKAからみずほPayPayドームの外周を冬の散歩道の様に、1周鑑賞いただけます。
                  <br />
                  さらにはBOSS Ｅ・ZO
                  FUKUOKA前に巨大なオーナメント型のオブジェ、
                  そして海側の植栽、みんなのガーデンには様々な形のオブジェがあり、光が包み込むフォトスポットとしてお楽しみいただけます。
                  <br />
                  期間限定の特別な景色をじっくり満喫ください。
                </p>
                <p className="text c-12 mb80">
                  期間：2024年11月24日（日）～2025年1月13日（月・祝）
                </p>
                <p className="text c-12">
                  BOSS E・ZO FUKUOKAは
                  <br className="sp" />
                  年末年始も休まず営業します。
                </p>
                <a
                  href="https://e-zofukuoka.com/news/info/2024110828517/"
                  className="text tac tx-link fwb c-12 is-2 is-2-sp"
                >
                  詳しくはこちら
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section">
        <div className="container">
          <div className="inner mb0">
            <h2 className="headline" id="area">
              <span>エリア</span>
            </h2>
            <div className="sc-columns now-event">
              {ILLUMINATIONS_CATEGORIES.map((categories, index) => (
                // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
                <div
                  className={`col col-4 ${categories.colorClass}`}
                  key={categories.name}
                  data-modal={index}
                  data-sal="slide-up"
                  data-sal-easing="ease-out-expo"
                  onClick={(e) => onClickModal(e)}
                >
                  <ContentsListIllumination {...categories} />
                </div>
              ))}
            </div>

            <p
              className="has-text-align-center has-text-weight-bold mt-6 mb-6 text is-3 c-12"
              data-sal="slide-up"
              data-sal-easing="ease-out-expo"
            >
              また、MARK IS 福岡ももちでもイルミネーション点灯を実施。
              <br className="sp" />
              みずほPayPayドームやBOSS E・ZO
              FUKUOKAを中心とした一帯のエリアで素敵なひとときをお過ごしください。
            </p>
            <div className="sc-columns now-event">
              {ILLUMINATIONS_CATEGORIES2.map((categories, index) => (
                // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
                <div
                  className={`col col-4 ${categories.colorClass}`}
                  key={categories.name}
                  data-modal={index}
                  data-sal="slide-up"
                  data-sal-easing="ease-out-expo"
                  onClick={(e) => onClickModal(e, 'building')}
                >
                  <ContentsListIllumination {...categories} />
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>

      <section className="section">
        <div className="container">
          <div className="inner mb0">
            <h2 className="headline">
              <span>冬イベント情報</span>
            </h2>
            <div className="sc-columns">
              {EVENT_BOX.map((category, index) => (
                // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
                <div
                  className={`col col-4 ${category.colorClass}`}
                  key={category.ttl}
                  data-modal={index}
                  data-sal="slide-up"
                  data-sal-easing="ease-out-expo"
                  onClick={(e) => onClickModal(e, 'event')}
                >
                  <ContentsListIlluminationBox {...category} />
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>

      <section className="section">
        <div className="container">
          <h2 className="headline">
            <span>施設</span>
          </h2>
          <div className="place-wrap">
            <div className="spot-col">
              <div
                data-sal="slide-up"
                data-sal-easing="ease-out-expo"
                className="spot-logo"
              >
                <a
                  href="https://www.softbankhawks.co.jp/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Image
                    src="illumination_logo-1.jpg"
                    alt="福岡ソフトバンクホークス"
                  />
                </a>
              </div>
            </div>
            <div className="spot-col">
              <div
                data-sal="slide-up"
                data-sal-easing="ease-out-expo"
                className="spot-logo"
              >
                <Link to="/">
                  <Image src="e-zologo.png" alt="福岡ソフトバンクホークス" />
                </Link>
              </div>
            </div>
            <div className="spot-col">
              <div
                data-sal="slide-up"
                data-sal-easing="ease-out-expo"
                className="spot-logo"
              >
                <a
                  href="https://www.mec-markis.jp/fukuoka-momochi/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Image
                    src="illumination_logo-3.jpg"
                    alt="MARK IS 福岡ももち"
                  />
                </a>
              </div>
            </div>
            <div className="spot-col">
              <div
                data-sal="slide-up"
                data-sal-easing="ease-out-expo"
                className="spot-logo"
              >
                <a
                  href="https://fukuokaseahawk.hiltonjapan.co.jp/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Image
                    src="illumination_logo-4.jpg"
                    alt="ヒルトン福岡シーホーク"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="inner-slim">
          <div className="table-base">
            <table>
              <tbody>
                <tr>
                  <th>チケット料金</th>
                  <td>
                    <p>
                      大人（中学生以上）: 1,600円
                      <br />
                      小人（小学生）: 900円
                    </p>
                    <p className="list-mark">
                      <span>※</span>
                      小学生未満無料（要保護者同伴）
                    </p>
                    {url.ticketReady === '1' ? (
                      <p className="underline-link">
                        <a
                          href={`${url.ticket}#/${url.uuidEvent}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          チケット購入はこちら
                        </a>
                      </p>
                    ) : null}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <h4 className="title is-5">注意事項</h4>
          <article>
            <ul className="list-base">
              <li>
                当館ご利用の際のお怪我や損失・損害、その他トラブルなどにつきまして当館では一切責任を負いません。
              </li>
              <li>
                営業中止を除き、いかなる場合もチケットの払い戻しは行っておりません。
              </li>
              <li>
                チケットの転売は禁止しており、転売にて購入いただいたチケットではご入場頂けず、その場合も当館は一切の責任を負いません。
              </li>
              <li>
                お子様連れのお客様は、保護者様の監督責任のもと安全にお過ごしください。
              </li>
              <li>
                <b>
                  小学生以下のお子様のご入場には、20歳以上の保護者の同伴が必要です。
                </b>
              </li>
              {fetchReady
                ? TimerFunc(
                    '2021-04-23 00:00:00',
                    '2021-05-01 00:00:00',
                    <li>
                      <b>
                        ※保護者1名に対し未就学児のお子様3名までご入場いただけます。（同伴者もチケット購入が必要です）
                      </b>
                    </li>
                  )
                : null}
              <li>
                <b>
                  ※保護者1名に対し未就学児のお子様2名までご入場いただけます。（同伴者もチケット購入が必要です）
                </b>
              </li>
              <li>
                イベントスペースでの喫煙、アメ・ガムを含む飲食はお断りしております。
              </li>
              <li>
                <b>会場内にトイレ・休憩室はございません</b>
              </li>
              <li>体調不良・泥酔状態でのご入場はお断りしております。</li>
              <li>
                全長50cm以上の荷物は1Fもしくは3Fのコインロッカー、荷物置場をご利用ください
              </li>
              <li>補助犬を除き、動物を連れてのご入場はお断りしております。</li>
              <li>事前承諾のない商業目的の撮影や取材はお断りしております。</li>
            </ul>

            <h4 className="ttl-strip">持ち込み禁止物</h4>
            <p>
              危険物、臭気物、生花など
              <br />
              ペットボトル、水筒以外の蓋が無いドリンク類
              <br />
              その他、スタッフが危険であると判断した物品
            </p>
          </article>
        </div> */}
      </section>
      <ModalContent />
    </Layout>
  );
};

export default Template;

export const ILLUMINATIONS_CATEGORIES = [
  {
    categories: 'team-lab', // カテゴリー名
    name: 'みずほPayPayドーム福岡', // エリア名
    // detail: '<p>ふつうのSNS映えでは満足できない人たちの心を満たす、フォトジェニックな空間。飛び交うシュールなうんこやカラフルに光り輝くうんこなど、さまざまなうんこを撮影してみましょう。</p>', // 説明
    img: 'illumination-building-1.jpg', // 画像名
    imgModal: 'illumination-building-1.jpg', // 画像名
    colorClass: 'floorbc-tr1', // シャドウカラー設定
    imgSlider: false,
  },
  {
    categories: 'team-lab', // カテゴリー名
    name: 'BOSS E・ZO FUKUOKA', // エリア名
    // detail: '<p>地面に映し出されたうんこを踏んづける体感ゲームや、うんこ〜！と叫んで声量に応じたうんこを生み出すゲームなど、子供心をくすぐるゲームをご用意しました。大人の方も、童心に返って全力でお楽しみください。</p>', // 説明
    img: 'illumination-building-2.jpg', // 画像名
    imgModal: 'illumination-building-2.jpg', // 画像名
    imgSlider: false,
  },
  {
    categories: 'team-lab', // カテゴリー名
    name: 'みずほPayPayドームとBOSS E・ZO FUKUOKA', // エリア名
    // detail: '<p>うんこを撮影する、うんこと恋する、うんこにダイブする…。何度でも遊びたくなる、中毒性の高いクソゲーが楽しめます。高得点を出せた時の爽快感は快便以上！</p>', // 説明
    img: 'illumination-building-3.jpg', // 画像名
    imgModal: 'illumination-building-3.jpg', // 画像名
    imgSlider: false,
  },
  {
    categories: 'team-lab', // カテゴリー名
    name: 'ホークスみんなのガーデン', // エリア名
    // detail: '<p>「勝うん様」に参拝し、“うん” をつけよう！「勝うん様」はミュージアム入場券をお持ちでない方もお楽しみいただけます。野球観戦の前に立ち寄って、ホークスの勝利を祈願しましょう！</p><p><span class="attention">※</span>勝うん様はミュージアム入場券をお持ちでない方もご入場いただけるエリアです</p>', // 説明
    img: 'illumination-building-4.jpg', // 画像名
    imgModal: 'illumination-building-4.jpg', // 画像名
    imgSlider: false,
  },
  {
    categories: 'team-lab', // カテゴリー名
    name: 'フォトスポット', // エリア名
    // detail: '<p>祈念を込めて黄金のうんこを撫でて、勝ちうん（運）をつけよう！<br />あなただけの「マイうんこ」に、ホークス勝利への熱い思いや祈りを込めたメッセージを記入してお供えすると、「勝うん様」に思いが届くかも・・?!！</p><p><span class="attention">※</span>撫でうんはミュージアム入場券をお持ちでない方もご入場いただけるエリアです</p>', // 説明
    img: 'illumination-building-5.jpg', // 画像名
    imgModal: 'illumination-building-5.jpg', // 画像名
    imgSlider: false,
  },
  {
    categories: 'team-lab', // カテゴリー名
    name: 'BOSS E・ZO FUKUOKA前', // エリア名
    // detail: '<p>祈念を込めて黄金のうんこを撫でて、勝ちうん（運）をつけよう！<br />あなただけの「マイうんこ」に、ホークス勝利への熱い思いや祈りを込めたメッセージを記入してお供えすると、「勝うん様」に思いが届くかも・・?!！</p><p><span class="attention">※</span>撫でうんはミュージアム入場券をお持ちでない方もご入場いただけるエリアです</p>', // 説明
    img: 'illumination-building-6.jpg', // 画像名
    imgModal: 'illumination-building-6.jpg', // 画像名
    imgSlider: false,
  },
];

export const ILLUMINATIONS_CATEGORIES2 = [
  {
    categories: 'team-lab', // カテゴリー名
    name: 'MARK IS 福岡ももち デッキイルミネーション', // エリア名
    img: 'illumination-building-8.jpg', // 画像名
    imgSlider: false,
    detail:
      '待ち合わせ場所にも使われる2階ももちステージのクリスマスツリーの森や、2階MARK ISデッキのクリスマスムード高まるイルミネーション、光の海をお楽しみください。',
    link: 'https://www.mec-markis.jp/fukuoka-momochi/event/detail.php?id=60665',
    isExternalLink: true,
    linkText: '詳細はこちら',
  },
];

export const EVENT_BOX = [
  {
    colorClass: 'floorbc-tr1', // シャドウカラー設定
    img: 'illumination_event-3.jpg',
    imgSlider: false,
    ttl: '【BOSS E・ZO FUKUOKA】この冬はE・ZOでパーティー！',
    descDl: '2024年11月12日（火）～2025年1月6日（月）',
    subTtl: '',
    desc:
      '冬はE・ZOでパーティー！！BOSS E・ZO FUKUOKAでこの冬開催するイベント情報をまとめてお知らせ！今年の冬は、ご家族やお友達、野球ファン仲間と一緒にワクワクイベント盛りだくさんのE・ZOにお越しください♪',
    link: 'https://e-zofukuoka.com/special/winter2024/',
    isExternalLink: false,
    linkText: '詳細はこちら',
  },
  {
    colorClass: 'floorbc-tr1', // シャドウカラー設定
    img: 'illumination_event-1.jpg',
    imgSlider: false,
    ttl: '【ヒルトン福岡シーホーク】クリスマス・トレイン2024',
    descDl: '2024年11月12日（火）～2025年1月6日（月）',
    desc:
      '今年で13回目を迎える「クリスマス・トレイン」は、ヒルトンの企業責任戦略『トラベル・ウィズ・パーパス（Travel with Purpose）』の一環でもある地域社会への貢献活動の一つです。企業、団体、個人の方々からご協賛いただき、児童養護施設で暮らす子供達の遊びや教育、学習支援等に使用しています。動く列車やカラフルな気球、観覧車などのアイテムで彩った冬のヨーロッパの景色を巨大ジオラマとして展示しておりますので、是非ご覧ください。<br/><br />展示場所<br />4階　ホテルロビー横<br/><br/>',
    link: 'https://fukuokaseahawk.hiltonjapan.co.jp/restaurants/lp/christmas',
    isExternalLink: true,
    linkText: '詳細はこちら',
  },
];

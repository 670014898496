/**
 * Type: コンポーネント
 * What: コンテンツリスト
 */
import { Link } from 'gatsby';
import React from 'react';
import Image from '../util/Image';
import SimpleSwiper from './Slider.fade';

export const List = (props) => (
  <div className="col-inner">
    <div className="col-image">
      <figure className="image">
        {props.imgSlider ? (
          <SimpleSwiper data={props.sliderImg} />
        ) : (
          <Image filename={props.img ? props.img : 'now-printing.jpg'} />
        )}
      </figure>
    </div>
    <div className={`col-content ${props.tac ? 'has-text-align-center' : ''}`}>
      <h3 className="ttl">{props.name}</h3>
      {props.description && <p className="text">{props.description}</p>}
    </div>
  </div>
);

export const ListBox = (props) => (
  <div className="col-inner">
    <div className="col-image">
      <figure className="image">
        {props.imgSlider ? (
          <SimpleSwiper data={props.sliderImg} />
        ) : (
          <Image filename={props.img ? props.img : 'now-printing.jpg'} />
        )}
      </figure>
    </div>
    <div className={`col-content ${props.tac ? 'has-text-align-center' : ''}`}>
      <div className="mb-1">
        <p className="ttl mb0">{props.ttl}</p>
        {props.subTtl && <p className="ttl mb0">{props.subTtl}</p>}
      </div>
      {props.descDl && <p className="text mt-3">{props.descDl}</p>}
    </div>
  </div>
);

export const ContentsListIllumination = (props) => (
  <>
    {props.noPointer ? (
      <div className="no-pointer">
        <List {...props} />
      </div>
    ) : (
      <div className="no-link">
        <List {...props} />
      </div>
    )}
  </>
);

export const ContentsListIlluminationBox = (props) => (
  <>
    {props.noPointer ? (
      <div className="no-pointer">
        <ListBox {...props} />
      </div>
    ) : (
      <div className="no-link">
        <ListBox {...props} />
      </div>
    )}
  </>
);
